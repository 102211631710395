import Dexie from "dexie";
import { Db } from "./Db";
import { Logger } from "./Logger";
import { RestClient } from "./RestClient";
import { SyncData } from "./SyncData";
import { ProjectSearchDto } from "./dto/ProjectSearchDto";
import { DefaultValues } from "./entity/DefaultValues";
import { Document } from "./entity/Document";
import { FrameSystem } from "./entity/FrameSystem";
import { Measurement } from "./entity/Measurement";
import { MeasurementTrade } from "./entity/MeasurementTrade";
import { Options } from "./entity/Options";
import { Project } from "./entity/Project";
import { ProjectTrade } from "./entity/ProjectTrade";
import { Setting } from "./entity/Setting";
import { Settings } from "./entity/Settings";
import { SettingsColor } from "./entity/SettingsColor";
import { SettingsConstructionType } from "./entity/SettingsConstructionType";
import { SettingsExportOption } from "./entity/SettingsExportOption";
import { SettingsOption } from "./entity/SettingsOption";
import { SettingsSashConfiguration } from "./entity/SettingsSashConfiguration";
import { SettingsSystemTypeDefaultValues } from "./entity/SettingsSystemTypeDefaultValues";
import { SettingsWeatherboard } from "./entity/SettingsWeatherboard";
import { SettingsWindowSystemFamilyDefaultValues } from "./entity/SettingsWindowSystemFamilyDefaultValues";
import { UserAcount } from "./entity/UserAccount";
import { WindowSystem } from "./entity/WindowSystem";
import { WindowSystemFamily } from "./entity/WindowSystemFamily";
import { ParentEntityType } from "./entity/enum/ParentEntityType";
import { SystemType } from "./entity/enum/SystemType";
import { MeasurementService } from "./service/MeasurementService";

export class Data {
  private static db = Db.Instance;
  // we use them for speed purpose, and mainly, because we can't use async function calls
  // inside of templates :-(
  private static cachedSettingsOptions: SettingsOption[] = [];
  private static cachedSettingsWindowSystemFamily: WindowSystemFamily[] = [];
  private static cachedSettingsFrameSystem: FrameSystem[] = [];
  private static cachedSettingsWeatherboard: SettingsWeatherboard[] = [];
  private static cachedSettingsSashConfiguration: SettingsSashConfiguration[] =
    [];
  private static cachedSettingsColor: SettingsColor[] = [];
  private static cachedSettingsConstructionType: SettingsConstructionType[] =
    [];
  private static cachedWindowSystem: WindowSystem[] = [];
  private static cachedSettingsExportOption: SettingsExportOption[] = [];

  public static async init() {
    // cachedSettingsOptions must be filled!!!
    // TODO: that's the same as cacheSettings() does!
    await Promise.all([
      this.getOptions(),
      this.getSettingsWindowSystemFamiliesActive(),
      this.getSettingsFrameSystems(),
      this.getSettingsSashConfigurations,
    ]);
  }

  /* Returns the user projects.
   * Those are the only ones which are cached and
   * stored to database, so we are sure they are always actual.
   * If we can't get it, we just use the one from database.
   */
  public static async getUserProjects(): Promise<Project[]> {
    let projects: Project[] = [];
    if (!(await this.isOnline())) {
      console.log("We are offline. Loading projects from db");
      projects = await this.db.projects.where({ isVisible: true }).toArray();
    } else {
      // first sync dirty data back. then get new data :-)
      await SyncData.syncDirtyData();
      // TODO performance: Like that it could happen, that at the beginning we have to wait long time
      // do it async!, especially loading the measurements. As first step, we could at least request the measurements parallely.
      projects = await RestClient.getUserProjects();
      await this.db.transaction(
        "rw",
        this.db.projects,
        this.db.measurements,
        this.db.documents,
        async () => {
          // TODO invisible projects will be deleted.
          await this.db.projects.clear();
          await this.db.measurements.clear();

          const allMeasurements = [];
          for (const project of projects) {
            project.isVisible = true;
            const projectMeasurements = await Dexie.waitFor(
              RestClient.getMeasurementsByProject(project)
            );
            for (const measurement of projectMeasurements) {
              allMeasurements.push(measurement);
            }
          }
          await this.db.measurements.bulkPut(allMeasurements);
          await this.db.projects.bulkPut(projects);
        }
      );
      // start getting new documents in background
      SyncData.initDocumentFetchSync();
    }
    return projects;
  }

  public static getProjectOfferUrl(project: Project): string {
    return RestClient.getProjectOfferUrl(project);
  }

  public static async addProjectToUserById(projectId: number) {
    await RestClient.addProjectToUserById(projectId);
    // TODO: like that we download all the projects again. do it more efficently please!
    // TODO: we don't even return that value?
    await this.getUserProjects();
  }

  public static async removeProjectFromUser(project: Project) {
    await RestClient.removeProjectFromUser(project);
    await this.db.documents
      .where({
        parentEntityId: project.id,
        parentEntityType: ParentEntityType.Project,
      })
      .delete();
  }

  /*
   * Returns the documents of a measuremnt from database
   */
  public static async getDocuments(
    parentEntityId: string | number,
    parentEntityType: ParentEntityType
  ): Promise<Document[]> {
    let documents: Document[] = [];
    if (parentEntityId) {
      if (!isNaN(Number(parentEntityId))) {
        // TODO: do we still need that???? (was pre typescript)
        parentEntityId = Number(parentEntityId);
      }
      documents = await this.db.documents
        .where({
          parentEntityId: parentEntityId,
          parentEntityType: parentEntityType,
        })
        .toArray();
    }
    return documents;
  }

  public static async getDocumentById(
    documentId: number
  ): Promise<Document | undefined> {
    return await this.db.documents.get(documentId);
  }

  public static async getProjectEntryYears(): Promise<number[]> {
    try {
      return await RestClient.getProjectEntryYears();
    } catch (e) {
      // we presume we are offline
      return [];
    }
  }

  public static async findProjects(
    year: number | null,
    filterString: string | null,
    projectLeader: UserAcount | null
  ): Promise<ProjectSearchDto[]> {
    return await RestClient.findProjects(year, filterString, projectLeader);
  }

  /*
   * Returns a project from the database
   */
  public static async getProjectByIdFromDb(
    projectId: string | number
  ): Promise<Project> {
    // TODO: should we update?
    // should we integrate it with the normal byiD?
    return (await this.db.projects.get(projectId)) as Project;
  }

  public static async getProjectByIdFromServer(
    projectId: number
  ): Promise<Project> {
    return await RestClient.getProjectById(projectId);
  }

  /*
   * First tries to get the project over rest and updates it on the db. if that fails, returns the project from db.
   */
  public static async getProjectById(
    projectId: number | string
  ): Promise<Project | null> {
    let project;
    project = await this.getProjectByIdFromDb(projectId);
    if (!isNaN(Number(projectId))) {
      try {
        // first sync back data
        await SyncData.syncDirtyData();
        const isVisible = project?.isVisible ? true : false;
        // then get newest data
        project = await this.getProjectByIdFromServer(projectId as number);
        await this.db.projects.put(project);
        if (!isVisible) {
          // project didn-t exist in db (or is not visible), so also get the measurements (even it it was in db before, but invisible projects
          // won't automatically be updated!
          const measurements = await this.getMeasurementsFromServer(project);
          await this.db.measurements.bulkPut(measurements);
          // start getting new documents in background
          SyncData.initDocumentFetchSync();
        }
      } catch (error: any) {
        if (error.isAxiosError) {
          // TODO we should throw an error
          project = null;
        } else {
          project = await this.getProjectByIdFromDb(projectId);
        }
      }
    }
    return project;
  }

  /*
   * Calls all settings and options, to be sure they are in  the pwa cache-
   */
  public static async cacheSettings() {
    this.getOptions();
    this.getSettings();
    this.getWindowSystems();
    this.getSettingsWindowSystemFamiliesActive();
    this.getSettingsFrameSystems();
    this.getSettingsColors();
    this.getSettingsConstructionTypes();
    this.getSettingsWeatherbords();
    this.getSettingsSashConfigurations();
    this.getSettingsSystemTypeDefaultValues();
    this.getSettingsWindowSystemFamilyDefaultValues();
    this.getSettingsExportOption();
    this.getUser();
  }

  public static async getOptions(): Promise<Options> {
    let options;
    try {
      options = await RestClient.getOptions();
      this.cacheOptions(options);
      await this.db.settings.put({ id: "options", data: options });
    } catch (error) {
      options = <Setting>await this.db.settings.get("options");
      options = <Options>options.data;
    }
    return options;
  }

  private static cacheOptions(optionsEnum: Options) {
    Data.cachedSettingsOptions = [];
    Object.entries(optionsEnum).forEach(
      ([key, value]) =>
        (Data.cachedSettingsOptions = Data.cachedSettingsOptions.concat(value))
    );
  }

  // gets options by id from the database.
  // it will NOT try to get new ones from the server
  public static getOptionsByIds(ids: number[]): SettingsOption[] {
    return Data.cachedSettingsOptions.filter((option) =>
      ids.includes(option.id)
    );
  }

  // gets option by id from the database.
  // it will NOT try to get new ones from the server
  public static getOptionById(id: number | null): SettingsOption | undefined {
    // TODO: would be more efficiently to filter directly instead of looping through everything as we do above
    if (id) {
      const options = this.getOptionsByIds([id]);
      if (options.length > 0) {
        return options[0];
      } else {
        Logger.debug("Couldn't find option for" + id);
        return undefined;
      }
    } else {
      Logger.debug("Couldn't find option for" + id);
      return undefined;
    }
  }

  public static async getUser(): Promise<UserAcount> {
    let user;
    try {
      // gets them from pwa cache if necessary
      user = await RestClient.getUser();
      await this.db.settings.put({ id: "user", data: user });
    } catch (error) {
      user = <Setting>await this.db.settings.get("user");
      user = user.data;
    }
    return user;
  }

  public static async getSettings(): Promise<Settings> {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettings();
      await this.db.settings.put({ id: "settings", data: settings });
    } catch (error) {
      settings = await this.db.settings.get("settings");
      settings = settings!.data;
    }
    return settings;
  }

  public static async getSettingsSashConfigurations(): Promise<
    SettingsSashConfiguration[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsSashConfigurations();
      // cache them
      Data.cachedSettingsSashConfiguration = settings;
      await this.db.settingsSashConfiguration.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsSashConfiguration.toArray();
    }
    return settings;
  }

  public static getSettingsSashConfigurationById(
    sashConfigurationId: number | null
  ): SettingsSashConfiguration | undefined {
    if (sashConfigurationId) {
      return Data.cachedSettingsSashConfiguration.find(
        (wsf) => wsf.id == sashConfigurationId
      );
    } else {
      return undefined;
    }
  }

  public static async getSettingsSashConfigurationsBySystemTypeAndNoOfSashes(
    systemType: SystemType | undefined | null,
    noOfSashes: string | number | null
  ): Promise<SettingsSashConfiguration[]> {
    if (noOfSashes && systemType) {
      let noOfSashesInt = noOfSashes;
      if (typeof noOfSashes === "string") {
        noOfSashesInt = parseInt(noOfSashes);
      }
      const settings = await this.db.settingsSashConfiguration
        .where({ noOfSashes: noOfSashesInt, systemType: systemType })
        .toArray();
      return settings;
    } else {
      return [];
    }
  }

  /*
  // only returns windowSystemFamilies which are active and NOT obsolete
  */
  public static async getSettingsWindowSystemFamiliesActive(): Promise<
    WindowSystemFamily[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsWindowSystemFamilies();
      // cache them
      Data.cachedSettingsWindowSystemFamily = settings;
      await this.db.settingsWindowSystemFamily.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsWindowSystemFamily.toArray();
    }
    settings = settings.filter(
      (wsf) => wsf.tenantUsesEntity && !wsf.isObsolete
    );
    return settings;
  }

  public static async getSettingsFrameSystems(): Promise<FrameSystem[]> {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsFrameSystems();
      // cache them
      Data.cachedSettingsFrameSystem = settings;
      await this.db.settingsFrameSystem.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsFrameSystem.toArray();
    }
    return settings;
  }

  /*
   * Returns result from DB, not rest
   */
  public static getSettingsWindowSystemFamilyById(
    id: number | null
  ): WindowSystemFamily | undefined {
    if (id) {
      return Data.cachedSettingsWindowSystemFamily.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }

  /*
   * Returns result from DB, not rest
   */
  public static getSettingsFrameSystemById(
    id: number | null
  ): FrameSystem | undefined {
    if (id) {
      return Data.cachedSettingsFrameSystem.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }

  /*
   * Returns result from DB, not rest
   */
  public static getSettingsColorById(
    id: number | null
  ): SettingsColor | undefined {
    if (id) {
      return Data.cachedSettingsColor.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }

  /*
   * Returns result from DB, not rest
   */
  public static getSettingsConstructionTypeById(
    id: number | null
  ): SettingsConstructionType | undefined {
    if (id) {
      return Data.cachedSettingsConstructionType.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }

  public static async getSettingsColorByIds(
    ids: number[]
  ): Promise<SettingsColor[]> {
    const results = await this.db.settingsColor.bulkGet(ids);
    const cleanedResults = [];
    for (const result of results) {
      if (result) {
        cleanedResults.push(result);
      }
    }
    return cleanedResults;
  }

  public static getSettingsExportOptionById(
    id: number | null
  ): SettingsExportOption | undefined {
    if (id) {
      return Data.cachedSettingsExportOption.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }

  public static getSettingsExportOptionBySystemType(
    systemType: SystemType | null
  ): SettingsExportOption[] {
    if (systemType) {
      return Data.cachedSettingsExportOption.filter(
        (item) => item.systemType === systemType
      );
    } else {
      return [];
    }
  }

  public static getSettingsWeatherboardById(
    id: number | null
  ): SettingsWeatherboard | undefined {
    if (id) {
      return Data.cachedSettingsWeatherboard.find((wsf) => wsf.id == id);
    } else {
      return undefined;
    }
  }
  public static async getSettingsWeatherboardByIds(
    ids: number[]
  ): Promise<SettingsWeatherboard[]> {
    const results = await this.db.settingsWeatherboard.bulkGet(ids);
    const cleanedResults = [];
    for (const result of results) {
      if (result) {
        cleanedResults.push(result);
      }
    }
    return cleanedResults;
  }

  public static async getSettingsColors(): Promise<SettingsColor[]> {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsColors();
      this.cachedSettingsColor = settings;
      await this.db.settingsColor.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsColor.toArray();
    }
    return settings;
  }

  public static async getSettingsConstructionTypes(): Promise<
    SettingsConstructionType[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsConstructionType();
      this.cachedSettingsConstructionType = settings;
      await this.db.settingsConstructionType.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsConstructionType.toArray();
    }
    return settings;
  }

  public static async getSettingsExportOption(): Promise<
    SettingsExportOption[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsExportOptions();
      this.cachedSettingsExportOption = settings;
      await this.db.settingsExportOption.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsExportOption.toArray();
    }
    return settings;
  }

  public static async getSettingsWeatherbords(): Promise<
    SettingsWeatherboard[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsWeatherboards();
      this.cachedSettingsWeatherboard = settings;
      await this.db.settingsWeatherboard.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsWeatherboard.toArray();
    }
    return settings;
  }

  public static async getSettingsSystemTypeDefaultValues(): Promise<
    SettingsSystemTypeDefaultValues[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsSystemTypeDefaultValues();
      await this.db.settingsSystemTypeDefaultValues.bulkPut(settings);
    } catch (error) {
      settings = await this.db.settingsSystemTypeDefaultValues.toArray();
    }
    return settings;
  }

  public static async getSettingsWindowSystemFamilyDefaultValues(): Promise<
    SettingsWindowSystemFamilyDefaultValues[]
  > {
    let settings;
    try {
      // gets them from pwa cache if necessary
      settings = await RestClient.getSettingsWindowSystemFamilyDefaultValues();
      await this.db.settingsWindowSystemFamilyDefaultValues.bulkPut(settings);
    } catch (error) {
      settings =
        await this.db.settingsWindowSystemFamilyDefaultValues.toArray();
    }
    return settings;
  }

  public static async getMeasurements(
    projectId: string | number
  ): Promise<Measurement[]> {
    const measurements = await this.db.measurements
      .where("projectId")
      .equals(projectId)
      .toArray();
    return measurements.sort((a, b) => a.positionNr - b.positionNr);
  }

  public static async getMeasurementsFromServer(
    project: Project
  ): Promise<Measurement[]> {
    return await RestClient.getMeasurementsByProject(project);
  }

  public static async getMeasurement(
    measurementId: number | string | null,
    projectId: number | string
  ): Promise<Measurement> {
    if (measurementId != null) {
      // TODO: should we update the projects?
      return <Measurement>await this.db.measurements.get(measurementId);
    } else {
      const project = await this.db.projects.get(projectId);
      return await MeasurementService.getNewProjectMeasurement(project!);
    }
  }

  public static async saveProjectCollapsed(
    project: Project,
    collapsed: boolean
  ) {
    // TODO: if projectId changes from string do number, it can't find the stored value anymore!!
    if (project.id) {
      await this.db.localProjectCollapsed.put({
        projectId: project.id,
        collapsed,
      });
    }
  }

  public static async isProjectCollapsed(
    project: Project
  ): Promise<boolean | undefined> {
    return (await this.db.localProjectCollapsed.get(project.id!))?.collapsed;
  }

  public static async saveProject(project: Project): Promise<Project> {
    let newProject: Project;
    try {
      if (project.id && isNaN(Number(project.id))) {
        // it is a Project that wasn't possible before to store to the server!
        // so try to sync it first, and then continue!
        // we need to store it first to the database, otherwise an older version would be synced.
        await this.db.projects.put(project);
        newProject = (await SyncData.syncDirtyProjectById(project.id))!;
      } else {
        newProject = await RestClient.saveProject(project);
      }
      this.db.projects.put(newProject);
    } catch (error: any) {
      Logger.logException(error);
      // no network (I Guess)
      newProject = project;
      if (!newProject.id) {
        newProject.id = await this.getNewProjectId();
      }
      await this.db.transaction(
        "rw",
        this.db.projects,
        this.db.dirtyProjects,
        async () => {
          await this.db.dirtyProjects.put({
            id: newProject.id!,
          });
          await this.db.projects.put(newProject);
        }
      );
    }
    return newProject;
  }

  public static async saveMeasurement(
    measurement: Measurement
  ): Promise<Measurement> {
    let newMeasurement: Measurement;
    try {
      // clone the object (don't reference it!)
      // TODO: I don't know anymore why we do that!
      newMeasurement = JSON.parse(JSON.stringify(measurement));
      if (measurement.id && isNaN(Number(measurement.id))) {
        // it is a measurement that wasn't possible before to store to the server!
        // so try to sync it first, and then continue!
        // we need to store it first to the database, otherwise an older version would be synced.
        await this.db.measurements.put(newMeasurement);
        newMeasurement = (await SyncData.syncDirtyMeasurementById(
          measurement.id!
        ))!;
      } else {
        newMeasurement = await RestClient.saveMeasurement(newMeasurement);
      }
      await this.db.measurements.put(newMeasurement);
      // TODO: we should add the measurement to the project if it was new!
    } catch (error: any) {
      Logger.logException(error);
      // The network is not available
      // or some other stupid problem ;-)
      newMeasurement = measurement;
      await this.db.transaction(
        "rw",
        this.db.measurements,
        this.db.dirtyMeasurements,
        async () => {
          if (!newMeasurement.id) {
            newMeasurement.id = await this.getNewMeasurementId();
            // TODO: we shold also add the newMeasurement to the project.
            // therefore we also need to update the measurementIds on the project
            // once we sync the measurement
          }
          await this.db.dirtyMeasurements.put({
            id: newMeasurement.id,
          });
          await this.db.measurements.put(newMeasurement);
        }
      );
    }
    return newMeasurement;
  }

  /*
   * returns the document if it was stored or already existed, otherwise null
   */
  public static async saveDocument(
    document: Document
  ): Promise<Document | null> {
    const result = await SyncData.saveDocument(document);
    return result;
  }

  public static async setDefaultValues(measurement: Measurement) {
    // TODO: at one point use a class, not an interface for windowSystemFamily, which automatically gets all those stupid data)
    const systemFamily = this.getSettingsWindowSystemFamilyById(
      measurement.settingsWindowSystemFamily
    )!;
    let defaultValues = <DefaultValues | undefined>(
      await this.db.settingsWindowSystemFamilyDefaultValues
        .where({ settingsWindowSystemFamily: systemFamily.id })
        .first()
    );
    if (!defaultValues) {
      // if there are no default values defined for the window, use the one for the system type
      // TODO: if both are defined, should the ones not set on family level be used from system type level
      defaultValues = <DefaultValues | undefined>(
        await this.db.settingsSystemTypeDefaultValues
          .where({ systemType: systemFamily.systemType })
          .first()
      );
    }
    // TODO: if already a value is set. should it be overwritten?
    measurement.hasManualInnerColor = false;
    measurement.hasManualOuterColor = false;
    const ignoreKeys = [
      "settingsWindowSystemFamily",
      "systemType",
      "id",
      "version",
    ];
    // unfortunately we can't loop over property of interface easily in typescript :-(
    Object.entries(defaultValues!).forEach(([key, value]) => {
      if (!ignoreKeys.includes(key)) {
        // @ts-ignore
        measurement[key] = value;
      }
    });

    // set default values depending on type
    if (systemFamily.systemType == SystemType.FRONT_DOOR) {
      // it can't be chosen!
      measurement.hasShortenedAluminiumShell = false;
      measurement.isBottomFrameExtensionFloorConnectionProfile = true;
      //    } else if (systemFamily.systemType == SystemType.WINDOW) {
    } else if (systemFamily.systemType == SystemType.LIFTING_SLIDING_DOOR) {
      measurement.isBottomFrameExtensionFloorConnectionProfile = true;
    }
  }

  /*
   * returns the document if it was stored or already existed, otherwise null
   */
  public static async deleteDocument(document: Document) {
    document.isDirty = true;
    document.isRestDelete = true;
    SyncData.saveDocument(document);
  }

  public static async deleteMeasurement(measurement: Measurement) {
    try {
      if (!isNaN(Number(measurement.id))) {
        RestClient.deleteMeasurement(measurement.id as number);
      }
      //delete all the documents in local db:
      // on server db, the above rest service call will delete it.
      for (const document of await this.getDocuments(
        measurement.id!,
        ParentEntityType.Measurement
      )) {
        this.db.documents.delete(document.id!);
      }
      this.db.measurements.delete(measurement.id!);
    } catch (error: any) {
      Logger.logException(error);
      // the network is not reachable
      measurement.isRestDelete = true;
      await this.db.measurements.put(measurement);
      await this.db.dirtyMeasurements.put({
        id: measurement.id!,
      });
    }
  }

  // TODO: put all those create new whatever into an own class. ServiceClasses!!
  public static async getNewProject(): Promise<Project> {
    // TODO generate project id
    return {
      id: null,
      projectLeaderUserAccount: await this.getUser(),
      projectTrades: [] as ProjectTrade[],
      isVisible: true,
    } as Project;
  }

  public static getNewProjectMeasurementTrade(
    measurement: Measurement
  ): MeasurementTrade {
    return {
      projectMeasurement: measurement.id!,
      isRestDelete: false,
      version: 0,
    } as MeasurementTrade;
  }

  public static getNewProjectTrade(project: Project): ProjectTrade {
    return {
      project: project.id,
      isRestDelete: false,
      version: 0,
    } as ProjectTrade;
  }

  // filters the possible "windowSystems", combinations of windowSystemFamilies, frames and weatherboards
  public static findWindowSystems(
    windowSystemFamilyId: number | null,
    weatherboardId?: number | null,
    frameSystemId?: number | null
  ): WindowSystem[] {
    if (windowSystemFamilyId) {
      let filteredList = this.cachedWindowSystem;
      filteredList = filteredList.filter((item) => {
        return (
          item.settingsWindowSystemFamily &&
          this.getSettingsWindowSystemFamilyById(
            item.settingsWindowSystemFamily
          )!.id == windowSystemFamilyId &&
          (weatherboardId == null ||
            item.settingsWeatherboard == weatherboardId) &&
          (frameSystemId == null || item.settingsFrameSystem == frameSystemId)
        );
      });
      return filteredList;
    } else {
      return [];
    }
  }

  public static async getSettingsWeatherboardByKey(
    key: string
  ): Promise<SettingsWeatherboard | undefined> {
    return await this.db.settingsWeatherboard
      .where("issKey")
      .equals(key)
      .first();
  }

  public static async isLoggedIn(): Promise<boolean> {
    //    if (process.env.VUE_APP_FENESTRACALC_URL.includes("localhost")) {
    //      // we are debugging! we assume to be always logged in then
    //      return true;
    //    }
    return await RestClient.isLoggedIn();
  }

  public static async hasDocuments(measurement: Measurement): Promise<boolean> {
    return (
      (await this.db.documents
        .where(["parentEntityId", "parentEntityType"])
        .equals([measurement.id!, ParentEntityType.Measurement])
        .count()) > 0
    );
  }

  /*
   * Returns true if all coarse measurement fields are filled out
   */
  // TODO: add all this functions here as methods to the object measurement (also cancalculateorder, etc.)
  public static hasCoarseMeasurement(measurement: Measurement): boolean {
    return (
      this.hasCoarseMeasurementHorizontal(measurement)
      && this.hasCoarseMeasurementVertical(measurement)
    );
  }

  /*
   * Returns true if all horiztaonl coarse measurement fields are filled out
   */
  public static hasCoarseMeasurementHorizontal(measurement: Measurement): boolean {
    return (
      measurement.innerWidth != null &&
      measurement.settingsOptionLeftDetail != null &&
      measurement.settingsOptionRightDetail != null
    );
  }

  /*
   * Returns true if all vertical coarse measurement fields are filled out
   */
  public static hasCoarseMeasurementVertical(measurement: Measurement): boolean {
    return (
      measurement.innerHeight != null &&
      measurement.settingsOptionTopDetail != null &&
      measurement.settingsOptionBottomDetail != null
    );
  }

  /*
  * Return true if all data necessary to calculate the orderMeasurement are filled out.
  */
  public static hasFineMeasurement(measurement: Measurement): boolean {
    return (this.hasFineMeasurementHorizontal(measurement) &&
      this.hasFineMeasurementVertical(measurement));
  }

  /*
  * Return true if all horizontal data to calculate horizontal orderMeasurement are filled out
  */
  public static hasFineMeasurementHorizontal(measurement: Measurement): boolean {
    // TODO: vue js sets empty number fields to "" instead of null.
    // maybe they'll change it in vue 3 (according to the bug report)
    return (this.notEmpty(measurement.outerWidth) &&
      // for whatever stupid reason. 0 is considered to be false in javascript
      // TODO: the stop values are missing
      // TODO: the overhang values if detail==6 are missing... argh !
      // measurement.leftDetailSize != null &&
      // measurement.rightDetailSize != null &&
      measurement.settingsOptionLeftDetail != null &&
      measurement.settingsOptionRightDetail != null &&
      measurement.settingsLeftFrameSystem != null &&
      measurement.settingsRightFrameSystem != null);
  }

  /*
  * Return true if all vertical data to calculate horizontal orderMeasurement are filled out
  */
  public static hasFineMeasurementVertical(measurement: Measurement): boolean {
    // TODO: vue js sets empty number fields to "" instead of null.
    // maybe they'll change it in vue 3 (according to the bug report)
    return this.notEmpty(measurement.outerHeight) &&
      // for whatever stupid reason. 0 is considered to be false in javascript
      // TODO: the stop values are missing
      // TODO: the overhang values if detail==6 are missing... argh !
      // measurement.topDetailSize != null &&
      measurement.settingsOptionTopDetail != null &&
      measurement.settingsOptionBottomDetail != null &&
      measurement.settingsTopFrameSystem != null &&
      measurement.settingsBottomFrameSystem != null
  }

  // returns true if all fineMeasurements are set PLUS order with & height
  public static hasOrderMeasurement(measurement: Measurement): boolean {
    return (
      this.hasFineMeasurement(measurement) &&
      measurement.orderWidth != null &&
      measurement.orderHeight != null
    );
  }

  /*
   * Returns true if there is at least one measurement, and all coarseMeasurements are filled out completely
   */
  public static async hasProjectCoarseMeasurement(
    project: Project
  ): Promise<boolean> {
    const measurements = await this.getMeasurements(project.id!);
    if (measurements.length == 0) {
      return false;
    }
    for (const measurement of measurements) {
      if (!this.hasCoarseMeasurement(measurement)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Returns true if there is at least one measurement, and all have the order measurements
   */
  public static async hasProjectOrderMeasurement(
    project: Project
  ): Promise<boolean> {
    const measurements = await this.getMeasurements(project.id!);
    if (measurements.length == 0) {
      return false;
    }
    for (const measurement of measurements) {
      if (!this.hasOrderMeasurement(measurement)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Returns true if there is at least one measurement, and all have the order approval
   */
  public static async hasProjectOrderApproval(
    project: Project
  ): Promise<boolean> {
    const measurements = await this.getMeasurements(project.id!);
    if (measurements.length == 0) {
      return false;
    }
    for (const measurement of measurements) {
      if (!measurement.hasOrderApproval) {
        return false;
      }
    }
    return true;
  }

  /*
   * Returns true, if not the whole project (including the measurements) has already
   * been synced back to the backend.
   */
  public static async isProjectDirty(project: Project): Promise<boolean> {
    if (await this.db.dirtyProjects.get(project.id!)) {
      return true;
    }

    const measurements = await this.getMeasurements(project.id!);
    for (const measurement of measurements) {
      if (await this.db.dirtyMeasurements.get(measurement.id!)) {
        return true;
      }
    }

    // check for dirtydocuments
    const dirtyDocuments = await this.db.dirtyDocuments.toArray();
    // TODO: not very beautiful. but as long as we don't have toooo many dirtydocuments. that efficient enough
    for (const dirtyDocument of dirtyDocuments) {
      // the equality check on the index should be quick. The documents won't get loaded into memory. Just the indexes checked and ocunted.

      // check for project documents
      if (
        (await this.db.documents
          .where(["parentEntityId", "parentEntityType"])
          .equals([project.id!, ParentEntityType.Project])
          .count()) > 0
      ) {
        return true;
      }
      // check for measurement documents
      for (const measurement of measurements) {
        if (
          (await this.db.documents
            .where(["parentEntityId", "parentEntityType"])
            .equals([measurement.id!, ParentEntityType.Measurement])
            .count()) > 0
        ) {
          return true;
        }
      }
    }

    return false;
  }

  public static async isOnline(): Promise<boolean> {
    return await RestClient.isOnline();
  }

  public static findDefaultValue(options: SettingsOption[]): number | null {
    for (const option of options) {
      if (option.isDefault === true) {
        return option.id;
      }
    }
    return null;
  }

  // TODO: put that into an other class
  public static validateForm(vueForm: Vue): string[] {
    vueForm.$v.$touch();
    if (vueForm.$v.$invalid) {
      const invalidFields = document.getElementsByClassName("invalidForExport");
      if (invalidFields.length > 0) {
        const firstInvalidField = invalidFields[0];
        firstInvalidField.scrollIntoView();
        const fieldNames = [] as string[];
        for (const field of invalidFields) {
          fieldNames.push(field.previousElementSibling!.innerHTML);
        }
        return fieldNames;
      }
      return [];
    } else {
      return [];
    }
  }

  private static async getWindowSystems(): Promise<WindowSystem[]> {
    let wSystems;
    try {
      wSystems = await RestClient.getWindowSystems();
      await this.db.settings.put({ id: "windowSystems", data: wSystems });
    } catch (error) {
      const setting = await this.db.settings.get("windowSystems");
      wSystems = setting!.data;
    }
    this.cachedWindowSystem = wSystems;
    return wSystems;
  }

  private static async getNewMeasurementId(): Promise<string> {
    let number;
    do {
      number = Math.floor(Math.random() * 10000000 + 1);
      number = "a" + number;
    } while (await this.db.measurements.get(number));
    return number;
  }

  private static async getNewProjectId(): Promise<string> {
    let number;
    do {
      number = Math.floor(Math.random() * 10000000 + 1);
      number = "a" + number;
    } while (await this.db.projects.get(number));
    return number;
  }

  private static notEmpty(value: unknown): boolean {
    return value != null && value !== "";
  }
}
