import LogRocket from "logrocket";

export class Logger {
  public static debug(msg: string) {
    // we don't send debug messages to logrocket
    LogRocket.captureMessage(msg);
    console.debug(msg);
  }

  public static info(msg: string) {
    LogRocket.captureMessage(msg);
    console.info(msg);
  }

  public static warn(msg: string) {
    LogRocket.captureMessage(msg);
    console.warn(msg);
  }

  public static error(msg: string) {
    LogRocket.captureMessage(msg);
    console.error(msg);
  }

  public static logException(error: Error) {
    LogRocket.captureException(error, { tags: { catchedException: true } });
    console.warn(error.stack);
  }
}
