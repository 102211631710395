import Vue from "vue";
import Vuex from "vuex";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import { EntityType } from "@/assets/ts/entity/enum/EntityType";
import { ChangedEntity } from "@/assets/ts/ChangedEntity";
import { Logger } from "@/assets/ts/Logger";

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

//TODO: in the future make it type safe.
// see maybe: vuex-class-component or direct-vuex or vuex-module-decorators
// or the new vuex 4 which should support it directly (but we need to update vue js therefore)
export const store = new Vuex.Store({
  state: {
    lastMeasurement: null,
    uploadDocuments: {
      noOfDocumentsLeft: 0,
      noOfKbTransfered: 0,
      noOfKbTotal: 0,
    },
    downloadDocuments: {
      noOfDocumentsLeft: 0,
      noOfKbTransfered: 0,
      noOfKbTotal: 0,
    },
    uploadQueue: [] as number[],
    downloadQueue: [] as number[],
    // This queue holds entities, of which the id has been updated while uploading. So
    // active views, can update it's identity!
    // for the moment also a single object would be ok, since we only have got one thread.
    // but in the future if there is a worker thread. It could be that severall changes are sent,
    // before the corresponding view is able to update itself.  We would need to use a SharedArrayBuffer then
    // but that's in the future. Right now safari wouldn't support it :-(.)
    // hmmmm or what ever. I'm not sure how that only one thread thing of javascript works exactly together with the store...
    changedEntityQueue: [] as ChangedEntity[],
    // when a document binary gets downloaded, the queue here gets updated, so the
    // app is able, to show the downloaded document to the user, if he happens to be in the right measurement/project
    downloadedDocumentBinaryQueue: [] as number[],
  },
  mutations: {
    setLastMeasurement(state, measurement) {
      state.lastMeasurement = measurement;
    },
    setUploadProgress(state, { kbTransfered, kbTotal }) {
      state.uploadDocuments.noOfKbTransfered = kbTransfered;
      state.uploadDocuments.noOfKbTotal = kbTotal;
    },
    setDownloadProgress(state, { kbTransfered, kbTotal }) {
      state.downloadDocuments.noOfKbTransfered = kbTransfered;
      state.downloadDocuments.noOfKbTotal = kbTotal;
    },
    addUpload(state, newDocumentId: number) {
      const index = state.uploadQueue.findIndex(
        (documentId) => documentId == newDocumentId
      );
      if (index < 0) {
        // the object is not in the queue yet. add it
        state.uploadQueue.push(newDocumentId);
        state.uploadDocuments.noOfDocumentsLeft++;
      }
    },
    addDownload(state, newDocumentId: number) {
      const index = state.downloadQueue.findIndex(
        (documentId) => documentId == newDocumentId
      );
      if (index < 0) {
        // the object is not in the queue yet. add it
        state.downloadQueue.push(newDocumentId);
        state.downloadDocuments.noOfDocumentsLeft++;
      }
    },
    removeNextItemFromUploadQueue(state) {
      const document = state.uploadQueue.shift();
      state.uploadDocuments.noOfKbTransfered = 0;
      if (document) {
        state.uploadDocuments.noOfDocumentsLeft--;
      } else {
        state.uploadDocuments.noOfKbTotal = 0;
        state.uploadDocuments.noOfKbTransfered = 0;
      }
    },
    removeNextItemFromDownloadQueue(state) {
      const document = state.downloadQueue.shift();
      state.downloadDocuments.noOfKbTransfered = 0;
      if (document) {
        state.downloadDocuments.noOfDocumentsLeft--;
      } else {
        state.downloadDocuments.noOfKbTotal = 0;
        state.downloadDocuments.noOfKbTransfered = 0;
      }
    },
    addChangedEntityToQueue(state, changedEntity: ChangedEntity) {
      Logger.debug(
        "Changed Id: " +
          changedEntity.oldId +
          " => " +
          changedEntity.newId +
          " of " +
          changedEntity.entityType
      );
      state.changedEntityQueue.push(changedEntity);
    },
    removeNextItemFromChangedEntityQueue(state) {
      state.changedEntityQueue.shift();
    },
    addDownloadedDocumentBinaryToQueue(state, documentId: number) {
      state.downloadedDocumentBinaryQueue.push(documentId);
    },
    removeNextItemFromDownloadedDocumentBinaryQueue(state) {
      state.downloadedDocumentBinaryQueue.shift();
    },
  },
  actions: {
    getNextUpload({ commit, state }): Promise<number | null> {
      return new Promise((resolve) => {
        let nextDocId = null;
        if (state.uploadQueue.length > 0) {
          nextDocId = state.uploadQueue[0];
        }
        commit("removeNextItemFromUploadQueue");
        resolve(nextDocId);
      });
    },
    getNextDownload({ commit, state }): Promise<number | null> {
      return new Promise((resolve) => {
        let nextDocId = null;
        if (state.downloadQueue.length > 0) {
          nextDocId = state.downloadQueue[0];
        }
        commit("removeNextItemFromDownloadQueue");
        resolve(nextDocId);
      });
    },
    getNextChangedEntity({ commit, state }): Promise<ChangedEntity | null> {
      return new Promise((resolve) => {
        let nextChangedEntity = null;
        if (state.changedEntityQueue.length > 0) {
          nextChangedEntity = state.changedEntityQueue[0];
          commit("removeNextItemFromChangedEntityQueue");
        }
        resolve(nextChangedEntity);
      });
    },
    getNextDownloadedDocumentBinary({ commit, state }): Promise<number | null> {
      return new Promise((resolve) => {
        let nextDownloadedDocumentBinary = null;
        if (state.downloadedDocumentBinaryQueue.length > 0) {
          nextDownloadedDocumentBinary = state.downloadedDocumentBinaryQueue[0];
          commit("removeNextItemFromDownloadedDocumentBinaryQueue");
        }
        resolve(nextDownloadedDocumentBinary);
      });
    },
  },
  modules: {},
  plugins: [logrocketPlugin],
});
