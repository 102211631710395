import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import NavProjects from "@/views/NavProjects.vue";
import { Oidc } from "@/assets/ts/Oidc";

// lazy loading
const NavProject = () =>
  import(/* webpackChunkName: "navProject" */ "@/views/NavProject.vue");
// const Measurements = () => import("./views/Measurements.vue");
const NavMeasurement = () =>
  import(/* webpackChunkName: "navMeasurement" */ "@/views/NavMeasurement.vue");
const Measurement = () =>
  import(/* webpackChunkName: "measurement" */ "@/views/Measurement.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "projects",
    component: NavProjects,
  },
  {
    path: "/project/:projectId/measurements",
    name: "nav-project",
    component: NavProject,
    props: castProjectParams,
  },
  {
    path: "/project/:projectId/measurement/:measurementId?",
    component: NavMeasurement,
    props: castMeasurementParams,
    children: [
      {
        path: "",
        component: Measurement,
        name: "measurement",
        props: castMeasurementParams,
      },
    ],
  },
  {
    path: "/login-callback",
    name: "login-callback",
    beforeEnter: async () => {
      await Oidc.loginCallback();
    },
  },
];

function castProjectParams(route: Route) {
  return {
    projectId: isNaN(Number(route.params.projectId))
      ? route.params.projectId
      : Number(route.params.projectId),
  };
}

function castMeasurementParams(route: Route) {
  return {
    projectId: isNaN(Number(route.params.projectId))
      ? route.params.projectId
      : Number(route.params.projectId),
    measurementId: isNaN(Number(route.params.measurementId))
      ? route.params.measurementId
      : Number(route.params.measurementId),
  };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
