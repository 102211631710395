/* eslint-disable no-console */

import { register } from "register-service-worker";
//import registerServiceWorker from 'service-worker-loader!./syncServiceWorker';
import { scriptUrl } from "service-worker-loader!./syncServiceWorker";

// if (process.env.NODE_ENV === "production") {
// register(`${process.env.BASE_URL}mergedServiceWorker.js`, {

// register(`${process.env.BASE_URL}${scriptUrl}`, {

register(`${scriptUrl}`, {
  registrationOptions: {
    // in the service worker scripts we use importScripts to get the service worker.
    //without that parameter the browser wouldn't notice if any of
    // the import scripts change
    updateViaCache: "none",
  },
  ready(registration) {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; please refresh.");
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
// }
