<template>
  <div>
    <div class="mod-header">
      <div v-if="user" class="headerUser">
        {{ user.username }} | <a :href="oauthUrl + '/logout'">Abmelden</a>
      </div>
      <sync-state />
      <h1>Ausmass.app</h1>

      <div class="headerBar">
        <div class="headerBreadcrumb"></div>

        <div class="headerStep">
          <router-link :to="'/'" class="active"
            ><i class="la la-home"></i>Übersicht</router-link
          >
        </div>
      </div>
    </div>
    <Projects />
  </div>
</template>

<script>
import Projects from "@/views/Projects.vue";
import { Data } from "../assets/ts/Data";
import SyncState from "@/components/SyncState.vue";

export default {
  name: "nav-projects",
  components: {
    Projects,
    SyncState,
  },
  data() {
    return {
      user: null,
      oauthUrl: process.env.VUE_APP_OAUTH_ISSUER,
    };
  },
  async created() {
    this.user = await Data.getUser();
  },
};
</script>
