import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
//import Multiselect from "@/components/MultiSelectExtend.vue";
//import "vue-multiselect/dist/vue-multiselect.min.css";
import { setupAuthenticationHeaders } from "@/assets/ts/RestClient";
import axios from "axios";
import LogRocket from "logrocket";
import VModal from "vue-js-modal";
import VueOnlinePlugin from "vue-navigator-online";
import { Component } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueToggles from "vue-toggles";
import { store } from "./store";

// fake commit 5

// automatically send authentication details with each request.
setupAuthenticationHeaders(axios);

console.log("logging activated");

Component.registerHooks(["validations"]);

if (process.env.NODE_ENV === "production") {
  LogRocket.init("py2p7l/ausmassapp-prod", {
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().indexOf("login") !== -1) {
          // Don't log any login network data!
          return null;
        }

        if (request.url.indexOf("notUser") !== -1) {
          // don't log notUser. the data is too big
          return null;
        }

        // otherwise log the request normally
        return request;
      },
    },
  });
}

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueOnlinePlugin);
Vue.use(VModal);

//Vue.component("multiselect", Multiselect);
Vue.component("v-select", vSelect);
Vue.component("VueToggles", VueToggles);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
