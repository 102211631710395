import * as oauth2 from "@panva/oauth4webapi";
import router from "@/router";

// TODO cleanup! a lot is copied from the example!
export class Oidc {
  private static issuer = new URL(process.env.VUE_APP_OAUTH_ISSUER);
  private static redirect_uri = process.env.VUE_APP_OAUTH_REDIRECT_URL;
  private static client: oauth2.Client = {
    client_id: "pwa-client",
    token_endpoint_auth_method: "none",
  };

  private static authorizationServer: oauth2.AuthorizationServer;

  public static async init() {
    if (!this.authorizationServer) {
      // get all the endpoints, and other authorization server infos
      this.authorizationServer = await oauth2
        .discoveryRequest(this.issuer)
        .then((response) =>
          oauth2.processDiscoveryResponse(this.issuer, response)
        );
    }
  }

  public static async authenticate() {
    await this.init();
    this.storePreviousPage();
    const code_verifier = oauth2.generateRandomCodeVerifier();
    // we need that again in the logincallback
    // sometimes sessionStorage gets lost, that=s why we also store
    // it in sessionStorage for emergency cases.
    // We don-t do that be default, since we don=t want problems with several open tabs
    sessionStorage.setItem("code_verifier", code_verifier);
    localStorage.setItem("code_verifier", code_verifier);
    const code_challenge = await oauth2.calculatePKCECodeChallenge(
      code_verifier
    );
    const code_challenge_method = "S256";
    const authorizationUrl = new URL(
      this.authorizationServer.authorization_endpoint!
    );
    authorizationUrl.searchParams.set("client_id", this.client.client_id);
    authorizationUrl.searchParams.set("code_challenge", code_challenge);
    authorizationUrl.searchParams.set(
      "code_challenge_method",
      code_challenge_method
    );
    authorizationUrl.searchParams.set("redirect_uri", this.redirect_uri);
    authorizationUrl.searchParams.set("response_type", "code");
    authorizationUrl.searchParams.set("scope", "openid");

    // redirect user to as.authorization_endpoint
    window.location.replace(authorizationUrl.toString());
  }

  /*
   * get-s called after beeing redirected from the authentication server to the landing page of ausmass.app
   */
  public static async loginCallback() {
    await this.init();

    const currentUrl: URL = new URL(window.location.toString());
    const params = oauth2.validateAuthResponse(
      this.authorizationServer,
      this.client,
      currentUrl,
      oauth2.expectNoState
    );
    console.log(params);
    if (oauth2.isOAuth2Error(params)) {
      console.log("error", params);
      throw new Error(); // Handle OAuth 2.0 redirect error
    }

    let code_verifier = sessionStorage.getItem("code_verifier");
    if (!code_verifier) {
      code_verifier = localStorage.getItem("code_verifier")!;
    }
    const response = await oauth2.authorizationCodeGrantRequest(
      this.authorizationServer,
      this.client,
      params,
      this.redirect_uri,
      code_verifier
    );
    let challenges: oauth2.WWWAuthenticateChallenge[] | undefined;
    if ((challenges = oauth2.parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        console.log("challenge", challenge);
      }
      throw new Error(); // Handle www-authenticate challenges as needed
    }
    const result = await oauth2.processAuthorizationCodeOpenIDResponse(
      this.authorizationServer,
      this.client,
      response
    );
    if (oauth2.isOAuth2Error(result)) {
      console.log("error", result);
      throw new Error(); // Handle OAuth 2.0 response body error
    }
    this.setToken(result.access_token);
    const claims = oauth2.getValidatedIdTokenClaims(result);
    this.redirectToPreviousPage();

    // // fetch userinfo response
    // {
    //   const response = await oauth2.userInfoRequest(this.authorizationServer, this.client, access_token);

    //   let challenges: oauth2.WWWAuthenticateChallenge[] | undefined;
    //   if ((challenges = oauth2.parseWwwAuthenticateChallenges(response))) {
    //     for (const challenge of challenges) {
    //       console.log("challenge", challenge);
    //     }
    //     throw new Error(); // Handle www-authenticate challenges as needed
    //   }

    //   const result = await oauth2.processUserInfoResponse(
    //     this.authorizationServer,
    //     this.client,
    //     sub,
    //     response
    //   );
    //   console.log("result", result);
    // }
  }

  public static getToken(): string | null {
    return localStorage.getItem("oidcToken");
  }

  public static setToken(token: string) {
    return localStorage.setItem("oidcToken", token);
  }

  private static storePreviousPage() {
    sessionStorage.setItem("previousLocation", window.location.toString());
  }

  private static redirectToPreviousPage() {
    const prevPage = sessionStorage.getItem("previousLocation");
    if (prevPage) {
      window.location.replace(prevPage);
    } else {
      router.replace({ path: "/" });
    }
  }
}
