import Dexie from "dexie";
import { Project } from "./entity/Project";
import { Measurement } from "./entity/Measurement";
import { DirtyEntity } from "./entity/DirtyEntity";
import { DirtyDocument } from "./entity/DirtyDocument";
import { Setting } from "./entity/Setting";
import { Document } from "./entity/Document";
import { DirtyProject } from "./entity/DirtyProject";
import { SettingsColor } from "./entity/SettingsColor";
import { SettingsWeatherboard } from "./entity/SettingsWeatherboard";
import { WindowSystemFamily } from "./entity/WindowSystemFamily";
import { SettingsSashConfiguration } from "./entity/SettingsSashConfiguration";
import { ParentEntityType } from "./entity/enum/ParentEntityType";
import { FrameSystem } from "./entity/FrameSystem";
import { SettingsSystemTypeDefaultValues } from "./entity/SettingsSystemTypeDefaultValues";
import { SettingsWindowSystemFamilyDefaultValues } from "./entity/SettingsWindowSystemFamilyDefaultValues";
import { SettingsExportOption } from "./entity/SettingsExportOption";
import { LocalProjectCollapsed } from "./entity/LocalProjectCollapsed";
import { SettingsConstructionType } from "./entity/SettingsConstructionType";

export class Db extends Dexie {
  public projects!: Dexie.Table<Project, string | number>;
  public measurements!: Dexie.Table<Measurement, string | number>;
  public documents!: Dexie.Table<Document, string | number>;
  public dirtyMeasurements!: Dexie.Table<DirtyEntity, string | number>;
  public dirtyDocuments!: Dexie.Table<DirtyDocument, string | number>;
  public dirtyProjects!: Dexie.Table<DirtyProject, string | number>;
  public settings!: Dexie.Table<Setting, string>;
  public settingsColor!: Dexie.Table<SettingsColor, number>;
  public settingsConstructionType!: Dexie.Table<
    SettingsConstructionType,
    number
  >;
  public settingsWeatherboard!: Dexie.Table<SettingsWeatherboard, number>;
  public settingsWindowSystemFamily!: Dexie.Table<WindowSystemFamily, number>;
  public settingsFrameSystem!: Dexie.Table<FrameSystem, number>;
  public settingsSashConfiguration!: Dexie.Table<
    SettingsSashConfiguration,
    number
  >;
  public settingsSystemTypeDefaultValues!: Dexie.Table<
    SettingsSystemTypeDefaultValues,
    number
  >;
  public settingsWindowSystemFamilyDefaultValues!: Dexie.Table<
    SettingsWindowSystemFamilyDefaultValues,
    number
  >;
  public settingsExportOption!: Dexie.Table<SettingsExportOption, number>;
  // Those enhtities are only stored in the browser.
  public localProjectCollapsed!: Dexie.Table<
    LocalProjectCollapsed,
    number | string
  >;

  // singleton!!
  private static instance: Db;

  public static get Instance() {
    if (Db.instance) {
      return Db.instance;
    } else {
      Db.instance = new Db();
      return Db.instance;
    }
  }

  private constructor() {
    super("ausmass.app");
    this.version(1).stores({
      projects: "id, *projectMeasurements",
      measurements: "id, projectId, version",
      documents: "id, projectMeasurementId, version",
      dirtyMeasurements: "id",
      dirtyDocuments: "id, measurementId",
      dirtyProjects: "id, version",
    });

    this.version(2).stores({
      settings: "id",
    });

    this.version(3).stores({
      settingsColor: "id",
      settingsWeatherboard: "id, key",
      settingsWindowSystemFamily: "id",
    });
    this.version(4).stores({
      settingsWeatherboard: "id, issKey",
    });
    this.version(5).stores({
      settingsSashConfiguration: "id, noOfSashes",
    });
    this.version(6).upgrade((trans) => {
      this.documents.clear();
    });
    this.version(7).stores({
      dirtyDocuments: "id, parentEntityId",
    });
    this.version(8).stores({
      dirtyDocuments: "id, [parentEntityId+parentEntityType]",
    });
    this.version(9)
      .stores({
        documents: "id, [parentEntityId+parentEntityType], version",
        dirtyDocuments: "id",
      })
      .upgrade((trans) => {
        // this.documents.toCollection().modify(document =>{
        //   document.parentEntityType = ParentEntityType.Measurement,
        //   document.parentEntityId =
        // })
        this.documents.clear();
      });
    this.version(10).stores({
      settingsFrameSystem: "id",
    });
    this.version(11).stores({
      settingsSystemTypeDefaultValues: "id",
      settingsWindowSystemFamilyDefaultValues: "id",
    });
    this.version(12).stores({
      settingsSystemTypeDefaultValues: "id, systemType",
      settingsWindowSystemFamilyDefaultValues: "id, settingsWindowSystemFamily",
    });

    this.version(13).stores({
      settingsExportOption: "id, systemType",
    });
    this.version(14).stores({
      settingsSashConfiguration: "id, noOfSashes, systemSettings",
    });
    this.version(15).stores({
      localProjectCollapsed: "projectId",
    });
    this.version(16).stores({
      settingsConstructionType: "id",
    });
    this.version(17).stores({
      projects: "id, *projectMeasurements, isVisible",
    });
  }
}
