<template>
  <div id="app" :class="{ blocked: isSpinnerActive }">
    <hollow-dots-spinner
      v-if="isSpinnerActive"
      :animation-duration="1000"
      :size="60"
      :color="'#ff1d5e'"
    />
    {{ spinnerMessage }}
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import LogRocket from "logrocket";
import { Data } from "./assets/ts/Data";
import { Oidc } from "@/assets/ts/Oidc";

export default {
  components: {
    HollowDotsSpinner,
  },
  data() {
    return {
      isSpinnerActive: false,
      spinnerMessage: "",
    };
  },
  async created() {
    // ok onw. this is uglz as hell.
    // in the router we verify if it is the callback side called. unfortunately this is done after app.vue was created
    // so if we are at the callback side. just do anzthing.
    if (window.location.toString().includes("/login-callback")) {
      return;
    }

    // TODO: check, is the spinner still working?
    let self = this;
    this.$root.$on("activateSpinner", (data) => {
      self.isSpinnerActive = true;
      self.spinnerMessage = data;
    });
    this.$root.$on("deactivateSpinner", () => {
      self.isSpinnerActive = false;
    }); //
    if (this.isOnline && !(await Data.isLoggedIn())) {
      Oidc.authenticate();
      // don-t continue to execute anything
      return;
    }
    await Data.init();
    // we don't need to await those data. We just want to be sure the newest version gets cached.
    Data.cacheSettings();

    let user = await Data.getUser();
    LogRocket.identify(user.username);

    // this.isLoggedIn = true;
  },
};
</script>

<style lang="less">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
