var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mod-loader"},[_c('div',{class:{
        donutSpinner:
          _vm.uploadState.noOfDocumentsLeft > 0 ||
          _vm.uploadState.noOfKbTotal > 0 ||
          _vm.downloadState.noOfDocumentsLeft > 0 ||
          _vm.downloadState.noOfKbTotal > 0,
      }}),_c('p',[_c('span',[_vm._v("Upload:")]),_c('b',[_vm._v(" "+_vm._s(_vm.uploadState.noOfKbTotal > 0 ? _vm.uploadState.noOfDocumentsLeft + 1 : _vm.uploadState.noOfDocumentsLeft)+" Datei(en) ")]),_vm._v(" ("+_vm._s(_vm.uploadState.noOfKbTransfered)+"/"+_vm._s(_vm.uploadState.noOfKbTotal)+"kb) ")]),_c('p',[_c('span',[_vm._v("Download:")]),_c('b',[_vm._v(" "+_vm._s(_vm.downloadState.noOfKbTotal > 0 ? _vm.downloadState.noOfDocumentsLeft + 1 : _vm.downloadState.noOfDocumentsLeft)+" Datei(en) ")]),_vm._v(" ("+_vm._s(_vm.downloadState.noOfKbTransfered)+"/"+_vm._s(_vm.downloadState.noOfKbTotal)+"kb) ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }