import { isMobile, isMobileSafari } from "mobile-device-detect";

export class VueSelectUtils {
  public static initVirtualKeyboardTrick() {
    if (isMobile) {
      const inputs = document.querySelectorAll(
        ".virtualkeyboardtrick input"
      ) as NodeListOf<HTMLInputElement>;
      inputs.forEach((input) => {
        input.setAttribute("readonly", "true");
        let clickCount = 0;
        let ignoreBlur = false;

        input.addEventListener("click", () => {
          clickCount++;
          if (clickCount >= 2) {
            input.removeAttribute("readonly");
            if (isMobileSafari) {
              // safari, doesn't automatically show the keyboard s soon as the
              // readonly attribute was removed, so we need to loose focus first and set it again.
              // it's also necessary to wait a short moment between blur and focus, otherwise it won't work.
              ignoreBlur = true;
              input.blur();
              setTimeout(() => input.focus(), 10);
            }
          }
        });

        input.addEventListener("blur", () => {
          if (!ignoreBlur) {
            clickCount = 0;
            input.setAttribute("readonly", "true");
          }
          ignoreBlur = false;
        });
      });
    }
  }
}
